import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import auth, {getUserTokenEpic, setAuthTokenEpic, getUsersEpic, authenticateEpic, getUserCompanyEpic, getEnterprisePlanEpic, signUpEpic, loginEpic, logoutEpic, logoutFromOtherTabEpic, forgotPasswordEpic, generateResetPasswordTokenEpic, getResetPasswordTokenEpic, updatePasswordEpic, getPasswordTokenEpic} from './auth';
import ports, {epics as portEpics, actions as portActions} from './ports';
import googlePlaces, {epics as googlePlaceEpics, actions as googlePlaceActions} from './googlePlaces';
import exchangeRates, {epics as exchangeRateEpics, actions as exchangeRateActions} from './exchangeRates';
import containerTypes, {epics as containerTypeEpics, actions as containerTypeActions} from './containerTypes';
import setting, { setSideNavViewEpic, getSideNavViewEpic} from './setting';
import shipper, { epics as shipperEpics, actions as shipperActions } from './shipper';
import users, { epics as userEpics, actions as userActions } from './users';
import linkFedexAccount, { epics as linkFedexAccountEpics, actions as linkFedexAccountActions } from './linkFedexAccount';
import lookup, { epics as lookupEpics, actions as lookupActions } from './lookup';
import fxe, { epics as fxeEpics, actions as fxeActions } from './fxe';
import fxl, { epics as fxlEpics, actions as fxlActions } from './fxl';
import shipmentPlanner, {epics as shipmentPlannerEpics, actions as shipmentPlannerActions } from './shipmentPlanner';
import enquiries, {epics as enquiryEpics, actions as enquiryActions } from './enquiries';
import feedback, {epics as feedbackEpics, actions as feedbackActions } from './feedback';
import quotes, {epics as quoteEpics, actions as quoteActions } from './quotes';
import documentsV2, { epics as documentEpics, actions as documentActions } from './documentsV2';
import companies, { epics as companyEpics, actions as companyActions } from './companies';
import bookings, { epics as bookingsEpics, actions as bookingsActions } from './bookings';
import tracking, { epics as trackingEpics, actions as trackingActions } from './tracking';

export const actions = {
  ports: portActions,
  googlePlaces: googlePlaceActions,
  containerTypes: containerTypeActions,
  exchangeRates: exchangeRateActions,
  shipper: shipperActions,
  linkFedexAccount: linkFedexAccountActions,
  lookup: lookupActions,
  fxe: fxeActions,
  fxl: fxlActions,
  shipmentPlanner: shipmentPlannerActions,
  enquiries: enquiryActions, 
  feedback: feedbackActions, 
  users: userActions,
  quotes: quoteActions,
  documentsV2: documentActions,
  companies: companyActions,
  bookings: bookingsActions,
  tracking: trackingActions,
}

export const epic = combineEpics(
  getUserTokenEpic,
  setAuthTokenEpic,
  getUsersEpic,
  authenticateEpic,
  getUserCompanyEpic,
  getEnterprisePlanEpic,
  signUpEpic,
  loginEpic,
  logoutEpic,
  logoutFromOtherTabEpic,
  forgotPasswordEpic,
  generateResetPasswordTokenEpic,
  getResetPasswordTokenEpic,
  updatePasswordEpic,
  getPasswordTokenEpic,
  setSideNavViewEpic,
  getSideNavViewEpic,
  ...portEpics,
  ...googlePlaceEpics,
  ...containerTypeEpics,
  ...exchangeRateEpics,
  ...shipperEpics,
  ...linkFedexAccountEpics,
  ...lookupEpics,
  ...fxeEpics,
  ...fxlEpics,
  ...shipmentPlannerEpics,
  ...enquiryEpics,
  ...feedbackEpics,
  ...userEpics,
  ...quoteEpics,
  ...documentEpics,
  ...companyEpics,
  ...bookingsEpics,
  ...trackingEpics,
);

export default combineReducers({
  auth,
  ports,
  googlePlaces,
  containerTypes,
  exchangeRates,
  setting,
  shipper,
  linkFedexAccount,
  lookup,
  fxe,
  fxl,
  shipmentPlanner,
  enquiries,
  feedback,
  users,
  quotes,
  documentsV2,
  companies,
  bookings,
  tracking
});
